import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Box, Heading, Flex, Icon } from "@chakra-ui/react"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import Layout from "../components/layout"
import Banner from "../components/english/banner"
import Strategies from "../components/english/strategies"
import Portfolios from "../components/english/portfolios"
import AboutUs from "../components/english/aboutUs"
import SEO from "react-seo-component"

import Header from "../components/english/header_phone"

import ResponsivePlayer from "../components/minPlayer/minPlayer"
import minvideo from "../assets/minvideo.mp4"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      placeholderImage: file(relativePath: { eq: "banner.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 825
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      backgroundImage1: file(relativePath: { eq: "bg1.png" }) {
        childImageSharp {
          gatsbyImageData(
            height: 490
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)

  const banner = getImage(data.placeholderImage)

  const bg1 = getImage(data.backgroundImage1)

  return (
    // 顶部导航
    <Layout>
      {/* 缩小的手机导航栏 */}
      <Header />

      <SEO
        title={"Home"}
        titleTemplate={"Metaverse Industry Association Macau"}
        titleSeparator={`-`}
        description={
          "The Metaverse Industry Association Macau (MIAM) is committed to supporting the development of metaverse tech and innovations, and to build a community of like-minded industry players and enthusiasts."
        }
        // image={"https://i.ibb.co/6RWBX1d/Mindfulness-Capital.png"}
        image={"https://i.ibb.co/sJwr3F6/miam-logo.png"}
        pathname={"mainpage"}
        siteLanguage={"en"}
        siteLocale="en_gb"
        twitterUsername={"Metaverse Industry Association Macau"}
      />

      <Box mt="4rem" />
      {/* first row  */}
      {/* pc */}
      <Box
        maxW="1100px"
        margin="0 auto"
        padding="0 auto"
        // h={{md:'36rem'}}
        display={{base: "none",sm: "none",md: "flex"}}
        >
        <ResponsivePlayer isPhone={false} url={minvideo} controls={false} playing={true} isCenterVideo={false}/>
      </Box>
      {/* phone */}
      <Box
        maxW="1100px"
        margin="0 auto"
        padding="0 auto"
        // h={{sm:'20rem'}}
        display={{base: "flex",sm:"flex", md: "none"}}
        >
        <ResponsivePlayer isPhone={true} url={minvideo} controls={true} playing={false}  isCenterVideo={false}/>
      </Box>

      {/* first row  */}
      {/* <BgImage image={banner}>
        <Banner />
      </BgImage> */}

      {/* second row  */}
      <Box maxW="1100px" margin="0 auto" mt="4rem">
        <BgImage image={bg1}>
          <Strategies />
        </BgImage>
      </Box>

      {/* third row */}
      <Box maxW="1100px" margin="0 auto">
        <Portfolios />
      </Box>

      {/* fourth row  */}
      <Box maxW="1100px" margin="0 auto">
        <AboutUs />
      </Box>
    </Layout>
  )
}

export default IndexPage

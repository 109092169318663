import { Box, Grid, Heading, Text } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const AboutUs = () => {
  return (
    <Box maxW="1240px" margin="0 auto" mx={8} mb={20}>
      {/* title  */}
      <section id="About">
        <Heading
          pt={7}
          color="#333"
          fontSize={{ base: "xl", md: "2xl" }}
          borderBottom="4px solid #E2E8F0"
        >
          <Box as="span" borderBottom="4px solid #2B6CB0">
            Our
          </Box>
          &nbsp;Members
        </Heading>
      </section>
      {/* thomas */}
      <Grid
        templateColumns={{
          sm: "repeat(1, 1fr)",
          md: "repeat(1, 1fr)",
          lg: "repeat(1, 1fr)",
          xl: "repeat(1, 1fr)",
          // md: "repeat(2, 1fr)",
          // lg: "repeat(2, 1fr)",
          // xl: "repeat(2, 1fr)",
        }}
        w="100%"
        gap={5}
        mt={{ base: 10, md: 14 }}
      >
        {/* <StaticImage
          src="../../images/ThomasAo.png"
          alt="A dinosaur"
          placeholder="blurred"
          // layout="fixed"
          // width={568}
          // height={334}
        /> */}

        <Box>
          <Heading
            color="#333"
            letterSpacing="tight"
            // fontSize={{ sm: "xl", md: "xl", lg: "3xl", xl: "3xl" }}
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          >
            Thomas Ao
          </Heading>

          <Box
            w={10}
            h={1}
            bgColor="#333"
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          />

          <Text color="#666" mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }} fw="600">
            President and founder of MIAM, Ao is the founding partner of
            Mindfulness Capital, specialized in blockchain, metaverse, NFT and
            Web 3.0.
          </Text>
        </Box>
      </Grid>
      {/* fernando */}
      <Grid
        templateColumns={{
          sm: "repeat(1, 1fr)",
          md: "repeat(1, 1fr)",
          lg: "repeat(1, 1fr)",
          xl: "repeat(1, 1fr)",
          // md: "repeat(2, 1fr)",
          // lg: "repeat(2, 1fr)",
          // xl: "repeat(2, 1fr)",
        }}
        w="100%"
        gap={5}
        mt={{ base: 10, md: 14 }}
      >
        {/* <StaticImage
          src="../../images/Fernandp.png"
          alt="A dinosaur"
          placeholder="blurred"
          // layout="fixed"
          // width={568}
          // height={334}
        /> */}

        <Box>
          <Heading
            color="#333"
            letterSpacing="tight"
            // fontSize={{ sm: "xl", md: "xl", lg: "3xl", xl: "3xl" }}
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          >
            Fernando Pereira
          </Heading>

          <Box
            w={10}
            h={1}
            bgColor="#333"
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          />

          <Text color="#666" mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }} fw="600">
            Co-founder of MIAM, founding partner of GROW UP ESPORTS. Fernando
            currently represents Macao as a full member of the International
            Esports Federation.
          </Text>
        </Box>
      </Grid>

      {/* terry */}
      <Grid
        templateColumns={{
          sm: "repeat(1, 1fr)",
          md: "repeat(1, 1fr)",
          lg: "repeat(1, 1fr)",
          xl: "repeat(1, 1fr)",
          // md: "repeat(2, 1fr)",
          // lg: "repeat(2, 1fr)",
          // xl: "repeat(2, 1fr)",
        }}
        w="100%"
        gap={5}
        mt={{ base: 10, md: 14 }}
      >
        {/* <StaticImage
          src="../../images/TerryYeung.png"
          alt="A dinosaur"
          placeholder="blurred"
          // layout="fixed"
          // width={568}
          // height={334}
        /> */}

        <Box>
          <Heading
            color="#333"
            letterSpacing="tight"
            // fontSize={{ sm: "xl", md: "xl", lg: "3xl", xl: "3xl" }}
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          >
            Terry Yeung
          </Heading>

          <Box
            w={10}
            h={1}
            bgColor="#333"
            mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }}
          />

          <Text color="#666" mt={{ sm: 3.5, md: 4, lg: 8, xl: 8 }} fw="600">
            Co-founder of MIAM, executive director at Imperium Technology
            Group (0776.HK), Terry is dedicated to providing an open GameFi
            platform for the global community and gamers.
          </Text>
        </Box>
      </Grid>
    </Box>
  )
}

export default AboutUs

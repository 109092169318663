import { Box, Center, Heading, Text } from "@chakra-ui/react"
import React from "react"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/swiper.min.css"
import "swiper/components/pagination/pagination.min.css"

import "../../style/swiper.css"

// import Swiper core and required modules
import SwiperCore, { Pagination } from "swiper/core"

import { StaticImage } from "gatsby-plugin-image"

// install Swiper modules
SwiperCore.use([Pagination])

const Strategies = () => {
  return (
    <Box maxW="1240px" margin="0 auto" mx={8}>
      {/* title  */}
      <section id="Strategies">
        <Heading
          pt={7}
          color="#333"
          fontSize={{ base: "xl", md: "2xl" }}
          borderBottom="4px solid #E2E8F0"
        >
          <Box as="span" borderBottom="4px solid #2B6CB0" letterSpacing="tight">
            Our
          </Box>
          &nbsp;Vision
        </Heading>
      </section>

      <Box pt={{ base: "10" }} pb={{ base: "10" }}>
        <br />
        <Text>
          The Metaverse Industry Association Macau (MIAM) is committed to
          supporting the development of metaverse tech and innovations, and to
          build a community of like-minded industry players and enthusiasts.
        </Text>
        <br />
      </Box>
    </Box>
  )
}

export default Strategies
